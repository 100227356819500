:root {
  --color-primary: rgb(233,29,38);
  --color-text: #000000;
  --color-text-lighter: #afafaf;
  --color-secondary: rgb(179, 179, 179);
  --color-background: rgb(241, 241, 241);
  --color-dark: rgb(23, 34, 43);
  --color-danger: #cf004c;
  --color-light-gray: #242930;
  --color-gray: #F1F5F9;
  --color-dark-gray: #e6e5e9;
  --color-light: #f8f8f8;
  --sidebar-width: 275px;
}

#sidebar.active {
  --sidebar-width: 0;
}

body {
  margin: 0;
  font-family: Inter, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text) !important;
  font-size: 11.5pt !important;
  background-color: var(--color-background) !important;
}

body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

#root {
  min-height: 100vh;
}

.header {
  max-width: calc(100% - var(--sidebar-width));
  width: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 999;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 1rem;
  display: flex;
}

.page-content-wrapper {
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.color-text {
  color: var(--color-text) !important;
}

.color-text-lighter {
  color: var(--color-text-lighter) !important;
}

.main-content-wrapper {
  width: 100%;
  overflow: auto;
  padding-right: var(--sidebar-width);
}
div#projectsDropdown {color: #C4611F;}
.main-container {
  padding: 1.5rem;
  padding-top: 70px;
  min-height: 100vh;
  margin: auto;
  background-color: #F1F5F9;
  width: 100%;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: bold !important;
}

.bg-gray-light {
  background-color: var(--color-light-gray) !important;
}

.bg-gray {
  background-color: var(--color-gray) !important;
}

.bg-background {
  background-color: var(--color-background) !important;
}

.bg-gray-hover:hover {
  background-color: var(--color-dark-gray) !important;
}

.btn-primary,
.bg-primary {
  background: rgb(239, 119, 38);
  background: linear-gradient(90deg,
      rgb(239, 119, 38) 0%,
      rgb(216, 107, 35) 28%,
      rgb(179, 88, 28) 81%,
      rgba(239, 119, 38, 1) 100%);
}

.btn-primary{
  border-color: var(--color-primary) !important;
}

.text-primary {
  color: var(--color-primary) !important;
}

.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.text-secondary {
  color: var(--color-secondary) !important;
}

.text-white {
  color: white !important;
}

.rounded-sm {
  border-radius: 0.6rem !important;
}

.rounded-lg {
  border-radius: 20px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.small {
  font-size: 15.5px !important;
}

.medium {
  font-size: 18.3px !important;
}

.main-auth-page-container {
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  border-radius: 2rem;
}

.w-fit {
  width: fit-content !important;
}

hr {
  border-top: 2px solid var(--color-gray) !important;
  opacity: 1 !important;
}

.card {
  background-color: white;
  border: 1px solid var(--color-gray) !important;
  border-radius: 1.2rem;
  padding: 1rem;
}

.btn-basic {
  border: 2px solid var(--color-gray) !important;
}
.sidebar {
  margin-inline-start: 1rem; /* Instead of margin-left */
  margin-inline-end: 1rem;   /* Instead of margin-right */
}

.sidebar-admin {
  background-color: #04101f !important;
}

.sidebar-admin .nav-item .nav-link {
  color: var(--color-secondary) !important;
}

.sidebar-admin .nav-item.active .nav-link,
.sidebar-admin .nav-item .nav-link:hover {
  color: white !important;
}

#sidebar {
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--color-light-gray);
  border-right: 1px solid rgb(235, 235, 235);
  transition: all 0.3s;
  padding-top: 1.5rem;
  padding-bottom: 1.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

#sidebar.active {
  margin-left: calc(0px - 275px);
}

.nav-item.active,
.nav-item.active .nav-link,
.nav-item:hover,
.nav-item:hover .nav-link {
  color: var(--color-light) !important;
}

.nav-item:hover,
.nav-item.active {
  background-color: transparent !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

#sidebar.active #toggleModalButton {
  left: 0;
  transform: translateX(0%);
  z-index: 999 !important;
}

#sidebar-logo {
  max-width: 35px;
  width: 100%;
}

.nav-item .nav-link {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 500;
  color: var(--color-text-lighter);
}

#toggleModalButton {
  left: var(--sidebar-width);
  transform: translateX(-50%);
  bottom: 3rem;
}

.auth-page-logo {
  max-width: 105px;
  width: 100%;
}

.nav-item .nav-link svg {
  font-size: 13px !important;
}

.task-card {
  padding: 1rem;
  border-radius: 1.2rem;
  background-color: white;
  box-shadow: 0 0 0 1px #edeae9, 0 1px 4px 0 rgba(109, 110, 111, 0.08);
  border: none !important;
  width: 100%;
}

.task-card:hover {
  background-color: var(--color-light);
  cursor: pointer;
}

@media only screen and (max-width: 855px) {
  #sidebar {
    position: fixed !important;
    z-index: 9999 !important;
  }
}

.form-control {
  border: none !important;
  background-color: var(--color-gray) !important;
}

.assignee-nav-item:hover {
  background-color: var(--color-dark-gray);
}

.project-card {
  text-decoration: none !important;
  min-height: 185px;
}

.project-card:hover {
  opacity: 0.8;
}

#create-project-page-wrapper {
  min-height: 100vh;
  width: 100%;
}

.transform-left-35 {
  transform: translateX(-35%);
}

.transform-left-75 {
  transform: translateX(-75%);
}

.home-hero-section {
  background-image: linear-gradient(0deg,
      #d8eeee,
      #d8eeee 25%,
      #64adc9 65%,
      #005986b4 90%,
      #005986c9);
  padding-top: 6rem;
  padding-bottom: 6rem;
  text-align: center;
}

.fw-400 {
  font-weight: 400 !important;
}

.sidebar-link-group {
  padding-bottom: 1.85rem;
}

.nav-tabs .nav-item .nav-link {
  color: var(--color-text);
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item .nav-link:hover {
  background-color: transparent;
  border-color: rgb(48, 48, 48);
  color: var(--color-text);
}

.vis-label .vis-inner {
  height: 120px;
}

.vis-label {
  height: 120px !important;
}

.vis-group {
  height: 120px !important;
  padding-right: 1.3rem !important;
}

.vis-item {
  background-color: var(--color-primary) !important;
  border-radius: 0.8rem;
  padding: 0.5rem !important;
}

.vis-item .vis-item-overflow {
  height: 30%;
}

#projectDescription {
  height: 33px !important;
  overflow: hidden;
}

.nav-item .nav-link.active,
.nav-item .nav-link:hover {
  font-weight: 500;
  color: #C4611F !important;
}

.custom-dropdown .title-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-dropdown .title {
  font-size: 12px;
  font-weight: 700;
}

.custom-notifications {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 200px;
  overflow-y: scroll;
  border-bottom: 1px solid #efefef;
}

.notifications-page {
  list-style: none;
  padding-left: 0;
}

.custom-notifications>li,
.notifications-page>li {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}

.custom-notifications>li.unread a,
.notifications-page>li.unread a {
  background: #fffaef;
}

.custom-notifications>li>a,
.notifications-page>li>a {
  display: block;
  padding: 20px;
  color: #393939;
}

.custom-dropdown .dropdown-menu {
  border: 1px solid transparent;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  margin-top: 1rem !important;
  padding-top: 0;
  padding-bottom: 0;
  padding: 10px;
  -webkit-transition: 0.3s margin-top ease, 0.3s opacity ease,
    0.3s visibility ease;
  -o-transition: 0.3s margin-top ease, 0.3s opacity ease, 0.3s visibility ease;
  transition: 0.3s margin-top ease, 0.3s opacity ease, 0.3s visibility ease;
  max-width: 380px;
}

.custom-notifications>li>a .text,
.notifications-page>li>a>div .text {
  width: -webkit-fill-available;
}

.custom-notifications>li>a .text strong,
.notifications-page>li>a>div .text strong {
  font-weight: 700;
  color: #000;
}

.custom-notifications>li>a .notification-sender-icon img,
.notifications-page>li>a .notification-sender-icon img {
  width: 52px;
  border-radius: 0.4rem;
}

.active>.page-link,
.page-link.active {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.page-link {
  color: var(--color-primary);
}

.page-link.active {
  color: white;
}

.dropdown-menu-left {
  right: 0 !important;
  left: unset !important;
}

.hover,
.hover-lg {
  transition: opacity 0.2s ease;
}

.hover:hover {
  opacity: 0.8;
}

.hover-lg:hover {
  opacity: 0.6;
}

.notification-row {
  color: var(--color-text);
  display: block;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.notification-row p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.notification-row:hover,
.notification-row.active {
  background-color: var(--color-background) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.project-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 35, 53, 0.822);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  border: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.project-image:hover .project-image-overlay,
.profile-image:hover .project-image-overlay {
  opacity: 1;
}

.markup-render {
  min-height: 200px;
  border: 2px solid;
  border-color: var(--color-secondary) !important;

}

.container-light {
  background-color: #F1F5F9 !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar {
  font-family: Inter, sans-serif !important;
}

.react-calendar button {
  border-radius: .5rem !important;
}

.product-card-image {
  max-height: 230px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.btn-google{
  background-color: #EA4335;
  color:white;
}
.btn-google:hover{
  background-color: #EA4335;
  color:#000;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar {
  font-family: Inter, sans-serif !important;
}

.react-calendar button {
  border-radius: .5rem !important;
}